import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["packagePanel", "packageRadio", "selectedPackageId"]

  connect() {
    this.updateSelectedPackage()
  }

  selectPackage(event) {
    const clickedPanel = event.currentTarget
    const radioButton = clickedPanel.querySelector('input[type="radio"]')
    radioButton.checked = true
    this.selectedPackageIdTarget.value = clickedPanel.dataset.packageId
    this.updateSelectedPackage()
  }

  updateSelectedPackage() {
    this.packagePanelTargets.forEach((panel, index) => {
      const radio = this.packageRadioTargets[index]
      if (radio.checked) {
        panel.classList.add("Panel--success")
        panel.classList.remove("Panel--subtle")
        this.selectedPackageIdTarget.value = panel.dataset.packageId
      } else {
        panel.classList.remove("Panel--success")
        panel.classList.add("Panel--subtle")
      }
    })
  }
}