import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  async editLesson(event) {
    event.preventDefault();

    const lesson_id = 45
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  }

  closeQuickLook(event) {
    event.preventDefault();

    const quickViewElement = document.querySelector("#school-dashboard-lesson-quick-look");
    quickViewElement.classList.add("u-hidden");
  }

  hideTestDetails(event) {
    event.preventDefault();

    const formElement = document.getElementById('test_details_form');
    formElement.setAttribute('hidden', true);

    const formElementStudent = document.getElementById('quick-look-student-book-dropdown');
    formElementStudent.removeAttribute('hidden');

    const formElementSlot = document.getElementById('add-open-slots-form');
    formElementSlot.setAttribute('hidden', true);

    const formElementScheduleClassroom = document.querySelector('#schedule-classroom-program-form');
    formElementScheduleClassroom.classList.add("u-hidden");
  }

  unhideTestDetails(event) {
    event.preventDefault();

    const formElement = document.getElementById('test_details_form');
    formElement.removeAttribute('hidden');
    
    const formElementStudent = document.getElementById('quick-look-student-book-dropdown');
    formElementStudent.removeAttribute('hidden');
    
    const formElementSlot = document.getElementById('add-open-slots-form');
    formElementSlot.setAttribute('hidden', true);
    const formElementScheduleClassroom = document.querySelector('#schedule-classroom-program-form');
    formElementScheduleClassroom.classList.add("u-hidden");
  }

  setupSlotDetails(event) {
    event.preventDefault();

    const formElement = document.getElementById('quick-look-student-book-dropdown');
    formElement.setAttribute('hidden', true);

    const formElementTest = document.getElementById('test_details_form');
    formElementTest.setAttribute('hidden', true);

    const formElementSlot = document.getElementById('add-open-slots-form');
    formElementSlot.removeAttribute('hidden');


    const formElementScheduleClassroom = document.querySelector('#schedule-classroom-program-form');
    formElementScheduleClassroom.classList.add("u-hidden");
  }

  setupScheduleClassroom(event) {
    event.preventDefault();

    // const formElement = document.getElementById('quick-look-student-book-dropdown');
    // formElement.setAttribute('hidden', true);

    const formElementTest = document.getElementById('test_details_form');
    formElementTest.setAttribute('hidden', true);

    const formElementSlot = document.getElementById('add-open-slots-form');
    formElementSlot.setAttribute('hidden', true);

    const formElementInstructor = document.getElementById('school_quick_look_add_lesson_instructor_and_location');
    formElementInstructor.setAttribute('hidden', true);

    const formElementPickupTime = document.getElementById('add-lesson-pickup-time-form');
    formElementPickupTime.setAttribute('hidden', true);

    const formElementStudent = document.getElementById('quick-look-student-book-dropdown');
    formElementStudent.removeAttribute('hidden');

    // show schedule-classroom-program-form
    const formElementScheduleClassroom = document.querySelector('#schedule-classroom-program-form');
    formElementScheduleClassroom.classList.remove("u-hidden");
  }
}